import { WixBiProfileWebapp } from '@wix/ambassador-wix-bi-profile-webapp/http';
import { AmbassadorBaseUrl } from '../../constants/ambassador';

export const getDataScienceSiteLanguage = async (
  userId: string,
  metaSiteId: string,
): Promise<string | undefined> => {
  const profileDataService = WixBiProfileWebapp(
    AmbassadorBaseUrl.ProfileDataService,
  ).ProfileDataService()();

  const { fields } = await profileDataService.get({
    fields: ['detected_site_language', 'site_language'],
    metasiteGuid: metaSiteId,
    userGuid: userId,
  });

  // new field with updating relevant data after site publish
  const detectedSiteLanguage =
    fields?.detected_site_language?.aSingleValue?.aString;

  if (detectedSiteLanguage) {
    return detectedSiteLanguage;
  }

  // old field with static data without further updates
  // using only for old scanned sites
  return fields?.site_language?.aSingleValue?.aString;
};
